import { Switch } from "@headlessui/react";
import React from "react";
import { BsFillBarChartFill } from "react-icons/bs";
import { RiBubbleChartFill } from "react-icons/ri";
import { classNames } from "../../helpers/classNames";

function ChartToggleBtn({ forcedGraphView, setForcedGraphView }) {
  return (
    <Switch
      checked={forcedGraphView}
      onChange={() => setForcedGraphView((prev) => !prev)}
      className={classNames(
        forcedGraphView ? "bg-indigo-100" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 w-24 h-10 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          forcedGraphView ? "translate-x-12" : "translate-x-0",
          "pointer-events-none relative inline-block h-9 w-10 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      >
        <span
          className={classNames(
            forcedGraphView
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <BsFillBarChartFill></BsFillBarChartFill>
        </span>
        <span
          className={classNames(
            forcedGraphView
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <RiBubbleChartFill></RiBubbleChartFill>
        </span>
      </span>
    </Switch>
  );
}

export default ChartToggleBtn;
