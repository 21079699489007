import React, { useEffect, useContext, useState } from "react";
import BuildingIcon from "../../images/building-icon.svg";
import "./BuildingSelector.css";
import { connect } from "react-redux";
import Toolbar from "./Toolbar/Toolbar";
import {
  handleSiteSelected,
  setAllEquipments,
  setAllPoints,
} from "../../redux/actions";
import { getEquipmentCount, getPointCounts } from "../API/API";
import { getAllEquipCounts } from "../../helpers/getAllEquipCounts";
import { MdSortByAlpha } from "react-icons/md";
import Loader from "../../Resueables/Loader/Loader";
import { classNames } from "../../helpers/classNames";

function BuildingSelector({
  handleSiteSelected,
  buildings,
  selectedSiteFromGlobal,
  handleBuildingSelectFromPopOver,
}) {
  const [sitesToShow, setSitesToShow] = useState(null);

  const [sortBy, setSortBy] = useState("alpha");

  const [loading, setLoading] = useState("");

  const [sitesCurrentlyDisplaying, setSitesCurrentlyDisplaying] = useState(
    buildings[sitesToShow ? sitesToShow : "allSites"]
  );

  useEffect(() => {
    setSitesCurrentlyDisplaying(
      buildings[sitesToShow ? sitesToShow : "allSites"]
    );
  }, [buildings, sitesToShow]);

  const buildingSelect = async (site) => {
    if (loading) {
      return;
    }
    try {
      handleSiteSelected(site);

      setLoading("");
      if (handleBuildingSelectFromPopOver) {
        handleBuildingSelectFromPopOver(site);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="mt-10 mx-auto mb-10" />

      <div
        className={classNames(
          "building-selector flex flex-wrap h-96 overflow-scroll w-full",
          loading ? "cursor-not-allowed" : ""
        )}
      >
        <div
          onClick={() =>
            buildingSelect({
              navName: "All Buildings",
              id: null,
              value: null,
              isAGroup: true,
            })
          }
          className={`building transition  flex border p-3 items-center border-sm rounded rounded-xl m-2 cursor-pointer max-h-20   ${
            selectedSiteFromGlobal.id == null ? "ring-4" : ""
          } `}
        >
          <div className="icon   mr-7 rounded  rounded-lg">
            {console.log(loading)}
            {loading.includes("All") ? (
              <Loader size="30px"></Loader>
            ) : (
              <img
                className="rounded rounded-lg"
                src={BuildingIcon}
                width="40"
              />
            )}
          </div>
          <div>
            <p className="color-darkblue font-bold text-sm my-0">
              All Buildings
            </p>
            <small className="color-darkblue">
              2,624,836 ft<sup>2</sup>
            </small>
          </div>
          <div className="ml-auto">
            <span className="badge kwh p-1 text-sm  font-medium px-2 mr-2 rounded-full">
              Kwh
            </span>

            <span className="badge steam text-sm p-1 px-2 font-medium  rounded-full">
              Steam
            </span>
          </div>
        </div>

        {sitesCurrentlyDisplaying.map((site) =>
          site.navName && (site.elecMeterId || site.steamMeterId) ? (
            <div
              onClick={() => buildingSelect(site)}
              className={`building transition  flex border p-3 items-center border-sm rounded rounded-xl m-2 cursor-pointer max-h-20  ${
                selectedSiteFromGlobal.id == site.id ? "ring-4" : ""
              }  `}
            >
              <div className="icon  mr-7 rounded  rounded-lg ">
                {loading == site.navName ? (
                  <Loader size="30px" />
                ) : (
                  <img
                    className="rounded rounded-lg"
                    src={BuildingIcon}
                    width="40"
                  />
                )}
              </div>

              <div>
                <p className="color-darkblue font-bold text-sm my-0">
                  {site.navName}
                </p>
                <small className="color-darkblue">
                  {site.area.toLocaleString()}
                </small>
              </div>
              <div className="ml-auto">
                {site.elecMeterId && (
                  <span className="badge kwh p-1 text-sm  font-medium px-2 mr-2 rounded-full">
                    Kwh
                  </span>
                )}
                {site.steamMeterId && (
                  <span className="badge steam text-sm p-1 px-2 font-medium  rounded-full">
                    Steam
                  </span>
                )}
              </div>
            </div>
          ) : null
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    buildings: state.buildings,
    selectedSiteFromGlobal: state.buildings.selectedSite,
    allSites: state.buildings.allSites,
    utilitiesSite: state.buildings.utilitiesSite,
    dormSite: state.buildings.dormSite,
    auxSite: state.buildings.auxSite,
    athleticSite: state.buildings.athleticSite,
    acadSite: state.buildings.acadSite,
  };
};

const mapDispatchToProps = {
  handleSiteSelected: handleSiteSelected,
  setAllEquipmentsToGlobalState: setAllEquipments,
  setAllPoints: setAllPoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingSelector);
