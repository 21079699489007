import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import { allCharts } from "../../helpers/equipmentCharts";
import "./MultipleCharts.css";
import Loader from "../../Resueables/Loader/Loader";
import { getRandomColor } from "../../helpers/getColors";
import MaxMinAvgFeed from "../../Resueables/MaxMinAvgFeed/MaxMinAvgFeed";
import moment from "moment";
import tConvert from "../../helpers/tConvert";
import { setErrorModal } from "../../redux/actions";
import { connect } from "react-redux";
import Modal from "../../Resueables/Modals/Modal";
import { BiErrorAlt } from "react-icons/bi";

function MultipleCharts({
  equipCharts,
  equipDataForADay,
  detailsPanelOpen,
  loadingSomething,
  errorModal,
  setErrorModal,
}) {
  const [chartInst, setChartInst] = useState([]);

  let equipChartsData = [];

  Object.keys(equipCharts).forEach((key) => {
    equipChartsData.push({
      name: key,
      data: equipCharts[key],
    });
  });

  useEffect(() => {
    try {
      for (let i in chartInst) {
        if (chartInst[i]) {
          chartInst[i].destroy();
        }
      }

      for (let i in equipChartsData) {
        const ctx = document
          .getElementById(equipChartsData[i].name)
          .getContext("2d");

        const gradient = ctx.createLinearGradient(0, 0, 0, 450);

        gradient.addColorStop(0, getRandomColor());
        gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
        gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

        const chart = new Chart(ctx, {
          data: {
            labels: equipChartsData[i].data.map((x) =>
              moment(x.ts.substring(0, x.ts.lastIndexOf("-"))).format("hh:mm a")
            ),
            datasets: [
              {
                fill: true,
                borderWidth: 2,
                backgroundColor: gradient,
                label: equipChartsData[i].name,
                data: equipChartsData[i].data.map((x) => Math.round(x.avg)),
              },
            ],
          },
          type: "line",
          options: {
            tooltips: {
              callbacks: {},
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  callback: (value, index, values) => {
                    return moment(
                      equipChartsData[i].data[index].ts.substring(
                        0,
                        equipChartsData[i].data[index].ts.lastIndexOf("-")
                      )
                    ).format("hh:mm a");
                  },
                },
              },
              y: {
                grid: {
                  display: false,
                },
                ticks: {
                  callback: (value, index, values) => {
                    return equipChartsData[i].data.unit
                      ? value + equipChartsData[i].data.unit
                      : value;
                  },
                },
              },
            },
          },
        });

        setChartInst((prev) => {
          return [...prev, chart];
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [equipCharts]);

  if (!equipCharts) {
    return <h1 className="text-center">No Data available</h1>;
  }
  return (
    <>
      <Modal
        open={errorModal && !loadingSomething}
        setOpen={setErrorModal}
        modalHeading="No data available"
        modalText="We are having trouble, fetching data at this time stamp"
        modalType="error"
        modalIcon={<BiErrorAlt className="" />}
      />
      <div id="charts" className="multi-charts">
        {loadingSomething && (
          <div className="w-full justify-center items-center">
            <Loader center size="120px" />
          </div>
        )}

        {equipChartsData.map((chart, idx) => (
          <div key={`equimentchart-${idx}`} className="chart">
            <div className="w-full" style={{ height: "200px" }}>
              <canvas id={chart.name}></canvas>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorModal: state.buildings.errorModal,
  };
};

const mapDispatchToProps = {
  setErrorModal: setErrorModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleCharts);
