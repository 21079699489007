import axios from "axios";
import {
  LOAD_AHU_EQUIPMET_DATA_QUERY,
  LOAD_FCU_EQUIPMET_DATA_QUERY,
  LOAD_ALL_SITES_QUERY,
  LOAD_EQUIPMENT_COUNT_QUERY,
  LOAD_VAV_EQUIPMET_DATA_QUERY,
  LOAD_WT_AND_KWH_FOR_A_DAY_QUERY,
  LOAD_WT_AND_STEAM_FOR_A_DAY_QUERY,
  LOAD_HEAT_EQUIPMET_DATA_QUERY,
  LOAD_BOILER_EQUIPMET_DATA_QUERY,
  LOAD_EQUIPMENT_OCCUPIED_STATUS,
  LOAD_EQUIPMENT_OCCUPIED_STATUS_QUERY,
  loadMeterInfoQuery,
  LOAD_TOTAL_KWH,
  LOAD_CHILLER_EQUIPMET_DATA_QUERY,
  LOAD_BOILER_EQUIPMENT_DATA_QUERY,
  LOAD_POINTS_COUNT,
  LOAD_WEATHER,
  INSERT_UTILITY_METER_DATA,
  GET_METERS_BY_TAG,
  ADD_METER_BILLS,
  GET_BILLS_BY_METER,
  GET_BILLS,
  formUpdateQuery,
  INSERT_VIEW,
  INSERT_USER_VIEWS,
  GET_VIEWS_BY_USER_AND_TYPE_AND_PAGEID,
  DELETE_VIEW,
  GET_VIEWS_BY_VIEW_ID,
  GET_PAGES_BY_USERID,
  SAVE_PAGE,
  UPDATE_PAGE_LANES,
  INSERT_INTO_EQ_TEMPLATES,
  GET_EQUIPMENT_DETAILS,
  ASSIGN_SITE_AND_EQUIPMENTS,
  GET_VIEWS_BY_SITE_AND_EQ,
  WEATHER_QUERY,
  EQUIPMENT_OCC_STATUSES,
} from "../../GraphQl/Queries";

import moment from "moment";

const headers = {
  headers: {
    "x-hasura-admin-secret": "BD#o(1Df/NZUfkc\\;w/A<0.7tcjN+W;)",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

const server_url = "https://haystack-server-next.vercel.app/api/read?filter=";
// const server_url = "http://localhost:3000/api/read?filter=";

export const read = (axon) => {
  console.log(server_url + axon);
  return axios.get(server_url + axon, {
    headers: headers,
  });
};

export const getEquipmentCount = (selectedSite, siteCat) => {
  let id = null;
  if (selectedSite) {
    id = selectedSite.id;
  }

  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_EQUIPMENT_COUNT_QUERY(id, siteCat),
    headers
  );
};

export const getWeatherForADay = (day) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_WEATHER(day),
    headers
  );
};

export const getWeather = (startDate, endDate, bucketType) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    WEATHER_QUERY(startDate, endDate, bucketType),
    headers
  );
};

export const getPointCounts = (selectedSite, siteCat) => {
  let id = null;
  if (selectedSite) {
    id = selectedSite.id;
  }
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_POINTS_COUNT(id, siteCat),
    headers
  );
};

export const getKwh = (time, siteId, siteCat) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_WT_AND_KWH_FOR_A_DAY_QUERY(
      time.startDate,
      time.endDate,
      siteId,
      siteCat,
      time.breakdown
    ),
    headers
  );
};

export const getSteam = (time, siteId, siteCat) => {
  console.log("TIME", time);
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_WT_AND_STEAM_FOR_A_DAY_QUERY(
      time.startDate,
      time.endDate,
      siteId,
      siteCat,
      time.breakdown
    ),
    headers
  );
};

export const getVavDataForADay = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_VAV_EQUIPMET_DATA_QUERY(
      startDate,
      endDate,
      siteId,
      siteCat,
      equipTags
    ),
    headers
  );
};

export const getAhuDataForADay = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_AHU_EQUIPMET_DATA_QUERY(
      startDate,
      endDate,
      siteId,
      siteCat,
      equipTags
    ),
    headers
  );
};

export const getFcuDataForADay = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_FCU_EQUIPMET_DATA_QUERY(
      startDate,
      endDate,
      siteId,
      siteCat,
      equipTags
    ),
    headers
  );
};

export const getChillerDataForADay = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_CHILLER_EQUIPMET_DATA_QUERY(
      startDate,
      endDate,
      siteId,
      siteCat,
      equipTags
    ),
    headers
  );
};

export const getBoilerDataForADay = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_BOILER_EQUIPMENT_DATA_QUERY(
      startDate,
      endDate,
      siteId,
      siteCat,
      equipTags
    ),
    headers
  );
};

export const getEqStatus = (siteId) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    LOAD_EQUIPMENT_OCCUPIED_STATUS_QUERY(siteId),
    headers
  );
};

export const loadEqInfo = (meter) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    loadMeterInfoQuery(meter),
    headers
  );
};

export const loadTotalMetric = (whichMetric, siteId, siteCat, date) => {
  if (whichMetric == "kwh") {
    return axios.post(
      "https://api.richmond.novo.cloud/v1/graphql",
      LOAD_TOTAL_KWH(siteId, siteCat, date),
      {
        headers: {
          "x-hasura-admin-secret": "BD#o(1Df/NZUfkc\\;w/A<0.7tcjN+W;)",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  }

  if (whichMetric == "steam") {
    return axios.post(
      "https://api.richmond.novo.cloud/v1/graphql",
      LOAD_TOTAL_KWH(siteId, siteCat, date),
      {
        headers: {
          "x-hasura-admin-secret": "BD#o(1Df/NZUfkc\\;w/A<0.7tcjN+W;)",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  }
};

export const getMetersByTags = (tags) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    GET_METERS_BY_TAG(tags),
    headers
  );
};

export const addMeterBills = (bills) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    ADD_METER_BILLS(bills),
    headers
  );
};

export const getBills = (meter_number, month, tags, year) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    GET_BILLS(meter_number, month, tags, year),
    headers
  );
};

export const updateBillByUid = (costConsMap) => {
  let updateString = " ";

  for (let i in costConsMap) {
    updateString += formUpdateQuery(
      i,
      costConsMap[i].meter_number,
      costConsMap[i].uid,
      costConsMap[i]
    );
  }

  const finalUpdateString = {
    query: `
    mutation MyMutation {
          ${updateString}
   }`,
    variables: null,
  };

  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    finalUpdateString,
    headers
  );
};

export const getStatus = () => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    {
      query: `
    query MyQuery {
      ApproxDmprPos : point_aggregate(where: {navName: {_eq: "ApproxDmprPos"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
        aggregate {
          count
        }
      }
      
      Effective_SP: point_aggregate(where: {navName: {_eq: "Effective_SP"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
        aggregate {
          count
        }
      }
      
      Room_Temp: point_aggregate(where: {navName: {_eq: "Room_Temp"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
        aggregate {
          count
        }
      }
      
      RH_Valve: point_aggregate(where: {navName: {_eq: "RH_Valve"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
        aggregate {
          count
        }
      }
      
      Airflow: point_aggregate(where: {navName: {_eq: "Airflow"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
        aggregate {
          count
        }
      }
      
      Duct_Temp: point_aggregate(where: {navName: {_eq: "Duct_Temp"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
        aggregate {
          count
        }
      }
      
    }
    `,
    },

    headers
  );
};

export const saveCostAndCons = (
  meter_number,
  cost,
  consumption,
  uid,
  tags,
  year,
  month
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    {
      query: `
      mutation MyMutation {
        insert_utility_meters_data(objects: {uid: "${uid}", year: "${year}", month: "${month}", tags: "${tags}", meter_number: "${meter_number}", consumption: "${consumption}", cost: "${cost}", timestamp: "${moment().format(
        "YYYY-MM-DD"
      )}T00:00:00"}, on_conflict: {constraint: utility_meters_data_pkey, update_columns: [cost, consumption, tags]}) {
          returning {
            consumption
            cost
            id
            meter_number
            month
            tags
            timestamp
            uid
            year
          }
        }
      }
    `,
    },
    headers
  );
};

export const insertIntoView = (
  viewId,
  properties,
  createdAt,
  tags,
  title,
  userId,
  pageId,
  date,
  status,
  lanes
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    INSERT_VIEW(
      viewId,
      properties,
      createdAt,
      tags,
      title,
      userId,
      pageId,
      date,
      status,
      lanes
    ),
    headers
  );
};

export const insertIntoUserView = (userId, viewIds) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    INSERT_USER_VIEWS(userId, viewIds),
    headers
  );
};

export const getAllViewsByUserIdAndViewType = (userId, pageId, type) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    GET_VIEWS_BY_USER_AND_TYPE_AND_PAGEID(userId, pageId, type),
    headers
  );
};

export const deleteViewById = (viewId) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    DELETE_VIEW(viewId),
    headers
  );
};

export const getViewsByViewId = (viewId) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    GET_VIEWS_BY_VIEW_ID(viewId),
    headers
  );
};

export const getAllPagesByUserId = (userid) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    GET_PAGES_BY_USERID(userid),
    headers
  );
};

export const savePage = (userid, pageIds) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    SAVE_PAGE(userid, pageIds),
    headers
  );
};

export const updatePageLanes = (pageId, lanes) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    UPDATE_PAGE_LANES(pageId, lanes),
    headers
  );
};

export const saveEquipmentTemplates = (
  equipmentName,
  equipmentType,
  equipmentProperties,
  sites
) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    INSERT_INTO_EQ_TEMPLATES(
      equipmentName,
      equipmentType,
      equipmentProperties,
      sites
    ),
    headers
  );
};

export const fetchViewBySiteAndEquipment = (equipmentType, site) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    GET_VIEWS_BY_SITE_AND_EQ(equipmentType, site),
    headers
  );
};

export const assignSiteAdndEquipment = (views) => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    ASSIGN_SITE_AND_EQUIPMENTS(views),
    headers
  );
};

export const eqOccStatus = () => {
  return axios.post(
    "https://api.richmond.novo.cloud/v1/graphql",
    EQUIPMENT_OCC_STATUSES(),
    headers
  );
};

export const getDisplayTable = () => {
  var config = {
    method: "get",
    url: "https://hhfgqr.sse.codesandbox.io/get_display_table",
  };

  return axios(config);
};
