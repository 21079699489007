import React, { useEffect, useState } from "react";
import Header from "../../Resueables/Header/Header";
import SingleChart from "../SingleChart/SingleChart";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useLocation, useParams } from "react-router-dom";
import MultipleCharts from "../MultipleCharts/MultipleCharts";
import "./MeterChart.css";
import BuildingDetailsScreen from "../BuildingDetailsScreen/BuildingDetailsScreen";
import { classNames } from "../../helpers/classNames";
import { CgDetailsMore } from "react-icons/cg";
import LeftSidePanel from "./LeftSidePanel";
import { getEqStatus, read } from "../API/API";
import { connect } from "react-redux";
import MultipleSitesChart from "../MultipleSitesChart/MultipleSitesChart";
import RightSidePanel from "./RightSidePanel";
import ForcedGraph from "../ForcedGraph/ForcedGraph";
import ChartToggleBtn from "./ChartToggleBtn";
import { resParse } from "../../helpers/parser";
import UniversalSearch from "../../Resueables/UniversalSearch";
import {
  aggregateEquipmentPoints,
  groupArrayOfObjects,
} from "../../helpers/aggregrateEquipmentPoints";

function MeterChart({ selectedSite, allSites, time }) {
  const params = useParams();

  const location = useLocation();

  // const [meterOptions, setMeterOptions] = useState([...allMeterOptions]);

  const [isEquipment, setIsEquipment] = useState(false);
  const [meterData, setMeterData] = useState({});

  const [equipCharts, setEquipCharts] = useState([]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    label: "Today",
  });

  const [weatherData, setWeatherData] = useState({});

  const [showTimePanel, setShowTimePanel] = useState(false);

  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);

  const [occupyAndCount, setOccupyAndCount] = useState({});

  const [loadingSomething, setLoadingSomething] = useState(false);

  const [resize, setResize] = useState(false);

  const [forcedGraphView, setForcedGraphView] = useState(false);

  // ------ FUNCTIONS FOR THE SIDEPANELS ------ ///
  useEffect(() => {
    (async () => {
      if (detailsPanelOpen && !Object.keys(occupyAndCount).length) {
        const eqOccAndCount = await getEqStatus(selectedSite.id);
        setOccupyAndCount(
          getAllEquipCountsAndOccupyStatus(eqOccAndCount.data.data.equip)
        );
      }
    })();
  }, [detailsPanelOpen]);

  ////------FUNCTIONS FOR SIDEPANELS ENDS --------///

  useEffect(() => {
    (async () => {
      setLoadingSomething(true);
      let meterData;

      const startDate = time.startDate;
      const endDate = time.endDate;

      if (params.meter == "kwh") {
        setIsEquipment(false);
        const kwhRes = await read(
          `dash_gql_returnElecMeterData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );
        const kwh = resParse(kwhRes.data.val);

        if (selectedSite.isAGroup) {
          meterData = kwh.data;
        } else {
          meterData = Object.values(kwh.data)[0];
        }

        setLoadingSomething(false);
      }

      if (params.meter == "steam") {
        console.log(selectedSite);
        setIsEquipment(false);
        const steamRes = await read(
          `dash_gql_returnSteamMeterData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );

        const steam = resParse(steamRes.data.val);

        if (selectedSite.isAGroup) {
          meterData = steam.data;
        } else {
          meterData = Object.values(steam.data)[0];
        }

        setLoadingSomething(false);
      }

      if (params.meter == "vav") {
        setIsEquipment(true);
        setLoadingSomething(true);

        const vavRes = await read(
          `dash_gql_returnVavData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );

        const vav = resParse(vavRes.data.val);

        const vavCharts = vav.data;

        let charts = aggregateEquipmentPoints(vavCharts);

        setEquipCharts(charts);
        setLoadingSomething(false);
      }

      if (params.meter == "ahu") {
        setIsEquipment(true);
        setLoadingSomething(true);
        const ahuRes = await read(
          `dash_gql_returnAhuData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );

        const ahu = resParse(ahuRes.data.val);
        const ahuCharts = ahu.data;
        let charts = aggregateEquipmentPoints(ahuCharts);
        setEquipCharts(charts);

        setLoadingSomething(false);
      }

      if (params.meter == "fcu") {
        setIsEquipment(true);
        setLoadingSomething(true);

        const fcuRes = await read(
          `dash_gql_returnFcuData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );

        const fcu = resParse(fcuRes.data.val);

        const fcuCharts = fcu.data;

        let charts = aggregateEquipmentPoints(fcuCharts);
        setEquipCharts(charts);

        setLoadingSomething(false);
      }

      if (params.meter == "chiller") {
        setIsEquipment(true);
        setLoadingSomething(true);

        const chillerRes = await read(
          `dash_gql_returnChillerPlantData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );

        const chiller = resParse(chillerRes.data.val);

        const chillerCharts = chiller.data;

        let charts = aggregateEquipmentPoints(chillerCharts);
        setEquipCharts(charts);

        setLoadingSomething(false);
      }

      if (params.meter == "boiler") {
        setLoadingSomething(true);
        setIsEquipment(true);

        const boilerRes = await read(
          `dash_gql_returnBoilerPlantData(${startDate}, ${endDate}, "${
            time.range || "day"
          }", ${
            (!selectedSite.isAGroup && selectedSite.id) || params.siteId
              ? "@" + selectedSite.id || params.siteId
              : "null"
          }, ${
            selectedSite.isAGroup && selectedSite.value
              ? `"${selectedSite.value}"`
              : null
          })`
        );

        const boiler = resParse(boilerRes.data.val);
        const boilerCharts = boiler.data;

        let charts = aggregateEquipmentPoints(boilerCharts);
        setEquipCharts(charts);

        setLoadingSomething(false);
      }

      setMeterData(meterData);
    })();
  }, [params, selectedSite, time]);

  return (
    <div className="flex">
      <div className="container mx-auto w-10/12">
        <Header />
        <div className="">
          <div>
            <div className="mt-10" />

            <UniversalSearch
              auotSwitch={true}
              forcedGraphView={forcedGraphView}
              detailsPanelOpen={detailsPanelOpen}
              showTimePanel={showTimePanel}
              setShowTimePanel={setShowTimePanel}
            />
            <div className="mt-10" />
            <div className="flex  justify-center w-full relative">
              {detailsPanelOpen && (
                <div
                  className="panel  p-4 left mr-2 slide-in-left bg-white rounded rounded-xl"
                  style={{ width: "25%" }}
                >
                  <LeftSidePanel
                    allSites={allSites}
                    selectedSite={selectedSite}
                    occupyAndCount={occupyAndCount}
                  />
                </div>
              )}

              <div
                className={classNames(
                  "transition-all",
                  detailsPanelOpen ? "w-1/2" : "w-full"
                )}
              >
                <div
                  className={classNames(
                    "rounded  rounded-2xl bg-white p-5 mb-20",
                    params.meter == "buildingdetails" ? "w-9/12 mx-auto" : ""
                  )}
                >
                  <div
                    className={classNames(
                      "header flex justify-around items-center"
                    )}
                  >
                    {!forcedGraphView && (
                      <div className="color-darkblue font-bold text-lg mr-auto">
                        {params.meter == "buildingdetails" ? (
                          <>Building Details</>
                        ) : (
                          <>{params.meter.toUpperCase() + " Usage"}</>
                        )}
                      </div>
                    )}

                    {!forcedGraphView && (
                      <div className="flex items-center">
                        {params.meter != "buildingdetails" && (
                          <div className="mr-5">
                            <button
                              type="button"
                              onClick={() =>
                                setDetailsPanelOpen((prev) => !prev)
                              }
                              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm font-medium  leading-4 font-medium rounded-md  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-black"
                            >
                              Details
                              <CgDetailsMore
                                className="ml-3 -mr-1 h-5 w-5"
                                color="#000"
                              />
                            </button>
                          </div>
                        )}

                        <div className="mr-5">
                          <ChartToggleBtn
                            forcedGraphView={forcedGraphView}
                            setForcedGraphView={setForcedGraphView}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {!forcedGraphView && <div className="mt-20" />}

                  <div className="chart-wrapper">
                    {forcedGraphView ? (
                      <div>
                        <div className="flex  absolute right-10 z-10 top-5 justify-end ml-auto">
                          <ChartToggleBtn
                            forcedGraphView={forcedGraphView}
                            setForcedGraphView={setForcedGraphView}
                          />
                        </div>
                        <ForcedGraph />{" "}
                      </div>
                    ) : null}

                    {isEquipment && !forcedGraphView ? (
                      <MultipleCharts
                        loadingSomething={loadingSomething}
                        equipCharts={equipCharts}
                        detailsPanelOpen={detailsPanelOpen}
                      />
                    ) : (
                      params.meter !== "buildingdetails" &&
                      !forcedGraphView &&
                      params.siteId && (
                        <SingleChart
                          detailsPanelOpen={detailsPanelOpen}
                          loadingSomething={loadingSomething}
                          meterData={meterData}
                          selectionRange={selectionRange}
                        />
                      )
                    )}

                    {params.siteId == null &&
                      selectedSite.isAGroup &&
                      !forcedGraphView &&
                      params.meter != "buildingdetails" && (
                        <h1>
                          <MultipleSitesChart
                            loadingSomething={loadingSomething}
                            isEquipment={isEquipment}
                            meterData={meterData}
                          />
                        </h1>
                      )}

                    {params.meter == "buildingdetails" && (
                      <BuildingDetailsScreen />
                    )}
                  </div>
                </div>
              </div>

              {detailsPanelOpen && (
                <div
                  style={{ width: "25%" }}
                  className="panel  p-4 right ml-2 slide-in-right  bg-white  rounded rounded-xl"
                >
                  <RightSidePanel />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
    selectedSite: state.buildings.selectedSite,
    time: state.search.universalSearchState.time,
  };
};

export default connect(mapStateToProps)(MeterChart);
