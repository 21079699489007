import React, { useContext, useEffect, useState } from "react";
import NeumorphBox from "../../../Resueables/NeumorphBox/NeumorphBox";
import { Menu } from "@headlessui/react";
import "./Toolbar.module.css";
import Dropdown from "../../../Resueables/Dropdown/Dropdown";

import { BuildingsContext } from "../../../Contexts/BuildingsContext";

function Toolbar({
  buildings,
  handleSiteCatChange,
  allSites,
  utilitiesSite,
  dormSite,
  auxSite,
  athleticSite,
  acadSite,
  sitesToShow,
  handleSort,
  sortBy,
}) {
  const siteOptions = [
    { value: null, label: "All Sites", count: allSites.length },
    {
      value: "utilitiesSite",
      label: "Utilities Site",
      count: utilitiesSite.length,
    },
    { value: "dormSite", label: "Dorm Site", count: dormSite.length },
    { value: "auxSite", label: "Aux Site", count: auxSite.length },
    {
      value: "athleticSite",
      label: "Athelietic Site",
      count: athleticSite.length,
    },
    { value: "acadSite", label: "Acad Site", count: acadSite.length },
  ];

  const [shouldSortBySqft, setShouldSortBySqft] = useState(false);
  const [shouldSortByAlpha, setShouldSortByAlpha] = useState(false);

  // const handleAplhaToggle = (e) => {
  //   setShouldSortByAlpha(e);
  //   // setShouldSortBySqft(!e)

  //   const sitesToShowCopy = JSON.parse(JSON.stringify(sitesToShow));
  //   let sortedallSites = [];
  //   if (e) {
  //     sortedallSites = sitesToShowCopy.sort((a, b) =>
  //       a.navName > b.navName ? 1 : -1
  //     );
  //     setSitesToShow(sortedallSites);
  //   } else {
  //     setSitesToShow(sitesToShowUnchnaged);
  //   }
  // };

  const handleSqftToggle = (e) => {
    setShouldSortBySqft(e);
    // const sitesToShowCopy =
    //   buildings[`${sitesToShow ? sitesToShow : "allSites"}`];

    // // const sitesToShowCopy = JSON.parse(JSON.stringify(sitesToShow));

    // if (e) {
    //   const allSitesSortedByArea = sitesToShowCopy.sort((a, b) => {
    //     if (a.area && b.area) {
    //       let one = a.area.toString().replace(/\D/g, "");
    //       let two = b.area.toString().replace(/\D/g, "");

    //       one = parseInt(one);
    //       two = parseInt(two);
    //       if (one < two) {
    //         return 1;
    //       } else {
    //         return -1;
    //       }
    //     }
    //   });
    //   setSitesToShow(allSitesSortedByArea);
    // } else {
    //   setSitesToShow(sitesToShowUnchnaged);
    // }
  };

  return (
    <div className="flex items-center w-full">
      <div className="flex w-1/2 items-center justify-center">
        <div className=" w-40 siteTypeFilter mr-8">
          <p className="color-darkblue font-bold mb-4">Select a Site Type</p>
          <Dropdown
            labelField="label"
            onChange={handleSiteCatChange}
            extraButtonClasses="bg-transparent text-blue border-grey"
            optionsWidth="130%"
            placeholder="All sites"
            options={siteOptions}
          />
          <div className="mb-5" />
        </div>
        <div className="flex flex-1 justify-center items-center"></div>
      </div>
    </div>
  );
}

export default Toolbar;
