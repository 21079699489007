import stripJsonTrailingCommas from "strip-json-trailing-commas";

export const resParse = (str) => {
  try {
    // str = JSON.stringify(str).toString();
    // str = str
    //   .replace(/\\n/g, "\\n")
    //   .replace(/\\'/g, "\\'")
    //   .replace(/\\"/g, '\\"')
    //   .replace(/\\&/g, "\\&")
    //   .replace(/\\r/g, "\\r")
    //   .replace(/\\t/g, "\\t")
    //   .replace(/\\b/g, "\\b")
    //   .replace(/\\f/g, "\\f");

    // str = str.replace(/[\u0000-\u0019]+/g, "");

    return JSON.parse(stripJsonTrailingCommas(str));
  } catch (err) {
    console.log("ERROR", err);
  }
};
