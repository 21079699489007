import { Chart } from "chart.js";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { maxMinAvg } from "../../helpers/maxMinAvg";
import {
  onlyHourPoints,
  onlyMonthPoints,
  onlyDayPoints,
} from "../../helpers/onlyHourPoints";
import tConvert from "../../helpers/tConvert";
import { setErrorModal } from "../../redux/actions";
import Loader from "../../Resueables/Loader/Loader";
import MaxMinAvgFeed from "../../Resueables/MaxMinAvgFeed/MaxMinAvgFeed";
import Modal from "../../Resueables/Modals/Modal";
import "./SingleChart.css";
import moment from "moment";

function SingleChart({
  meterData,
  loadingSomething,
  time,
  detailsPanelOpen,
  setErrorModal,
  errorModal,
}) {
  const [chartInst, setChartInst] = useState({});
  const [dataParameters, setDataParameters] = useState({});
  const [isRange, setIsRange] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [maxMinAvgMeter, setMaxMinAvgMeter] = useState({});
  const [maxMinAvgWeather, setMaxMinAvgWeather] = useState({});
  const params = useParams();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (meterData && Object.keys(meterData).length) {
      try {
        plotTheChart(meterData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [meterData, detailsPanelOpen]);

  const plotTheChart = (meterData) => {
    try {
      if (!meterData.nodes) {
        return setErrorModal(true);
      }

      const datasets = [
        {
          label:
            params.meter == "kwh"
              ? "Kwh: "
              : params.meter == "steam"
              ? "Steam: "
              : "",
          data: meterData.nodes.map((x) => x.avg),
          backgroundColor: params.meter == "kwh" ? "#0fa4e7" : "#ffba69",
          yAxisID: "leftYaxis",
        },
      ];

      const chartDataLocal = {
        labels: meterData.nodes.map((x) =>
          moment(x.ts.substring(0, x.ts.lastIndexOf("-"))).format(
            time.range == "month" ? "DD MMMM" : "hh:mm a"
          )
        ),
        datasets: datasets,
      };

      console.log(moment().date());

      if (chartInst && Object.keys(chartInst).length) {
        chartInst.destroy();
      }
      const ctxDOM = document.querySelector("#chartcanvas");

      if (!chartDataLocal.labels.length) {
        return setErrorModal(true);
      }

      var myChart = new Chart(ctxDOM, {
        type: "bar",
        data: chartDataLocal,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },

          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  style: "normal",
                  weight: "100",
                },
              },
            },
            leftYaxis: {
              position: "left",
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                precision: 1,
                beginAtZero: true,

                font: {
                  style: "normal",
                  weight: "100",
                },
                callback: (label) => {
                  return `${Math.floor(label)}  ${
                    params.meter == "kwh" ? "Kwh" : "lb/h"
                  }`;
                },
              },
            },
            rightYaxis: {
              grid: {
                display: false,
              },
              position: "right",
              ticks: {
                font: {
                  style: "normal",
                  weight: "100",
                },
                beginAtZero: true,
                callback: (label) => {
                  return label + " F";
                },
              },
            },
          },

          elements: {
            line: {
              fill: false,
            },
          },
        },
      });

      setChartInst(myChart);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    return () => {
      try {
        chartInst.destroy();
      } catch (e) {
        console.log(e);
      }
    };
  });

  return (
    <Fragment>
      <Modal
        open={errorModal}
        setOpen={setErrorModal}
        modalHeading="No data available"
        modalText="We are having trouble, fetching data at this time stamp"
        modalType="error"
        modalIcon={<BiErrorAlt className="" />}
      />

      <div className="chart-container">
        {loadingSomething && (
          <div className="w-full justify-center items-center">
            <Loader center size="120px" />
          </div>
        )}
        <canvas className="chartcanvas" id="chartcanvas"></canvas>
      </div>

      <div className="cards w-2/3  mt-14 flex">
        {maxMinAvgMeter && maxMinAvgMeter.metaData && (
          <div className="p-4 shadow">
            <MaxMinAvgFeed
              unit={
                params.meter == "kwh"
                  ? "kwh"
                  : params.meter == "steam"
                  ? "lb/h"
                  : ""
              }
              chart={maxMinAvgMeter}
            />
          </div>
        )}
        <div className="ml-10" />

        {maxMinAvgWeather && maxMinAvgWeather.metaData && (
          <div className="p-4 shadow">
            <MaxMinAvgFeed unit="F" chart={maxMinAvgWeather} />
          </div>
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    time: state.search.universalSearchState.time,
    errorModal: state.buildings.errorModal,
  };
};

const mapDispatchToProps = {
  setErrorModal: setErrorModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleChart);
