import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Popover, Transition, Dialog, Switch } from "@headlessui/react";
import "./UniversalSearch.styles.css";
import { useHistory, useParams } from "react-router-dom";
import { TiHome } from "react-icons/ti";
import TimePopOver from "./TimePopOver";
import SitesPopOver from "./SitesPopOver";
import MeterPopOver from "./MeterPopOver";
import { connect } from "react-redux";
import {
  handleMeterStateChange,
  handleMetricMultiSelect,
  handleSiteSelected,
  handleTimeStateChange,
  resetSearchState,
  setAllEquipments,
} from "../../redux/actions";
import { allMeterOptions } from "../../redux/reducers/search.reducers";
import moment from "moment";
import UtilityMeterPopOver from "./UtilityMeterPopOver";
import { getEquipmentCount } from "../../components/API/API";
import { getAllEquipCounts } from "../../helpers/getAllEquipCounts";
import { IoIosAddCircle } from "react-icons/io";
import addDataAnim from "../../images/addDataAnimation.gif";

function UniversalSearch(props) {
  const {
    auotSwitch,
    universalSearchState,
    equipmentCounts,
    handleMeterStateChange,
    handleTimeStateChange,
    selectedSite,
    setAllEquipments,
    setSelectedSite,
    detailsPanelOpen,
    setZoom,
    isUtilitySearchBar,
    forcedGraphView = false,
    setSelectedMetrics,
    resetSearchState,
    selectedUtilityMeter,
    setSelectedUtilityMeter,
    addDataBtn,
    setAddData,
  } = props;
  const history = useHistory();
  const params = useParams();

  const siteBtnRef = useRef();
  const meterBtnRef = useRef();
  const timeBtnRef = useRef();
  const utilityPopOverRef = useRef();

  // const [panelOpen, setPanelOpen] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [currentlyOpenPanel, setCurrentlyOpenPanel] = useState(null);

  const panelToggleHandler = (open, whichPanel) => {
    if (open) {
      return setCurrentlyOpenPanel(null);
    }
    if (!open) {
      setCurrentlyOpenPanel(whichPanel);
    }
  };

  useEffect(() => {
    handleMeterStateChange(
      allMeterOptions.filter((x) => x.value == params.meter)[0]
    );
  }, []);

  const handleMeterChange = (meter) => {
    if (forcedGraphView) {
      setSelectedMetrics(meter);
    } else {
      handleMeterStateChange(meter);

      meterBtnRef.current.click();

      if (!meter.isEquipment && !meter.isUtility) {
        handleTimeStateChange({
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          label: "Today",
          range: undefined,
          breakdown: "hour",
        });
      } else {
        if (meter.isEquipment) {
          handleTimeStateChange({
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            label: "Today",
            range: undefined,
            breakdown: "hour",
          });
        }

        if (meter.isUtility) {
          handleTimeStateChange({
            startDate: moment().subtract("1", "year").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            label: moment().format("YYYY"),
            range: "year",
            breakdown: "month",
          });
        }
      }

      if (selectedSite.navName && meter) {
        if (meter.isUtility) {
          return history.push(`/dashboard/utilities/${meter.value}`);
        } else {
          history.push(
            `/dashboard/${meter.value}/${
              selectedSite.id ? selectedSite.id : ""
            }`
          );
        }
      } else {
        alert("Please select a System");
      }

      // timeBtnRef.current.click();
    }
  };

  const handleTimeSelect = (startDate, endDate, label, range, breakdown) => {
    // timeBtnRef.current.click();

    handleTimeStateChange({ startDate, endDate, label, range, breakdown });

    if (selectedSite.navName && universalSearchState.meter) {
      if (universalSearchState.meter.isUtility) {
        return history.push(
          `/dashboard/utilities/${universalSearchState.meter.value}`
        );
      } else {
        history.push(
          `/dashboard/${universalSearchState.meter.value}/${
            selectedSite.id ? selectedSite.id : ""
          }`
        );
      }
    } else {
      alert("Please select a System");
    }
  };

  const handleHomeButtonClick = async () => {
    try {
      history.push("/dashboard");
      setCurrentlyOpenPanel(null);
      resetSearchState();
      let eqCount;
      if (!localStorage.getItem("eqCountForAll")) {
        const equipments = await getEquipmentCount(null, null);
        eqCount = getAllEquipCounts(equipments.data.data.equip_aggregate.nodes);
      } else {
        eqCount = JSON.parse(localStorage.getItem("eqCountForAll"));
      }

      setAllEquipments(eqCount);

      setZoom(15);
    } catch (e) {
      console.log(e);
    }
  };

  const showGraph = () => {
    meterBtnRef.current.click();
  };

  useEffect(() => {
    handleMeterStateChange(
      allMeterOptions.filter(
        (x) => x.isUtility && x.value == params.utilityMeter
      )[0]
    );
  }, [params.utilityMeter]);

  const [enabled, setEnabled] = useState(false);

  return (
    <>
      <Popover.Group className="relative z-10 bg-white  flex searchbar rounded rounded-lg items-center fade-in-bck">
        <div onClick={handleHomeButtonClick} className="">
          <button className="rounded home-button  bg-white rounded-lg p-2 ml-3 my-3">
            <TiHome
              size="27px"
              className="text-2xl home-icon hover:text-blue cursor-pointer primary-text-color"
            />
          </button>
        </div>

        <div className="ml-7" />

        <div className="font-bold">Show:</div>

        <div className="ml-7" />

        <SitesPopOver
          panelToggleHandler={panelToggleHandler}
          setZoom={setZoom}
          auotSwitch={auotSwitch}
          selectedSite={selectedSite}
          siteBtnRef={siteBtnRef}
          meterBtnRef={meterBtnRef}
          timeBtnRef={timeBtnRef}
          // checkIfPanelIsOpen={checkIfPanelIsOpen}
          currentlyOpenPanel={currentlyOpenPanel}
        />

        <div className="ml-4" />

        <MeterPopOver
          showGraph={showGraph}
          forcedGraphView={forcedGraphView}
          panelToggleHandler={panelToggleHandler}
          currentlyOpenPanel={currentlyOpenPanel}
          auotSwitch={auotSwitch}
          detailsPanelOpen={detailsPanelOpen}
          selectedSite={selectedSite}
          handleMeterChange={handleMeterChange}
          meterOptions={universalSearchState.meter.options}
          equipmentCounts={equipmentCounts}
          siteBtnRef={siteBtnRef}
          meterBtnRef={meterBtnRef}
          timeBtnRef={timeBtnRef}
          isAGroup={universalSearchState.site.isAGroup}
          meterLabel={universalSearchState.meter.label}
        />

        {universalSearchState.meter.isUtility ? (
          <>
            {/* <div className="ml-4" /> */}

            {/* <div className="font-bold">For:</div> */}

            <div className="ml-4" />

            <UtilityMeterPopOver
              utilityPopOverRef={utilityPopOverRef}
              setSelectedUtilityMeter={setSelectedUtilityMeter}
              selectedUtilityMeter={selectedUtilityMeter}
            />
          </>
        ) : null}

        <div className="ml-4" />

        <div className="font-bold">During:</div>

        <div className="ml-4" />

        <TimePopOver
          isUtilitySearchBar={isUtilitySearchBar}
          showTimePicker={showTimePicker}
          universalSearchState={universalSearchState}
          setShowTimePicker={setShowTimePicker}
          panelToggleHandler={panelToggleHandler}
          currentlyOpenPanel={currentlyOpenPanel}
          auotSwitch={auotSwitch}
          handleTimeSelect={handleTimeSelect}
          siteBtnRef={siteBtnRef}
          meterBtnRef={meterBtnRef}
          timeBtnRef={timeBtnRef}
          timeFrames={universalSearchState.time.options}
          timeLabel={universalSearchState.time.label}
        />

        {addDataBtn ? (
          <div
            onClick={() => setAddData((prev) => !prev)}
            className="ml-auto mr-3"
          >
            <button className="rounded home-button  bg-white rounded-lg p-2 ml-3 my-3 relative">
              <div
                style={{
                  top: "-5px",
                  right: "-3px",
                  backgroundColor: "#0da4e7",
                }}
                className="dot absolute rounded rounded-full bg-indigo-900 w-3 h-3 "
              ></div>
              <img src={addDataAnim} width="32px" alt="Add Data animation" />
              {/* <IoIosAddCircle
                size="27px"
                className="text-2xl home-icon hover:text-blue cursor-pointer primary-text-color"
              /> */}
            </button>
          </div>
        ) : null}
      </Popover.Group>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    universalSearchState: state.search.universalSearchState,
    equipmentCounts: state.buildings.equipmentCounts.count,
    selectedSite: state.buildings.selectedSite,
  };
};

const mapDispatchToProps = {
  handleMeterStateChange: handleMeterStateChange,
  handleTimeStateChange: handleTimeStateChange,
  setAllEquipments: setAllEquipments,
  setSelectedSite: handleSiteSelected,
  setSelectedMetrics: handleMetricMultiSelect,
  resetSearchState: resetSearchState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UniversalSearch);
