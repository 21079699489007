import React, { Fragment, useEffect } from "react";
import Header from "../../Resueables/Header/Header";
import NeumorphBox from "../../Resueables/NeumorphBox/NeumorphBox";
import webAuth from "./auth0congif";
import "./Login.css";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

function Login() {
  const history = useHistory();

  const loginHandler = () => {
    webAuth.popup.authorize(
      {
        connection: "google-oauth2",
        owp: true,
      },
      function (err, authRes) {
        if (err) {
          return alert("Something went wrong !");
        }
        if (!err) {
          localStorage.setItem("accessToken", authRes.accessToken);
          webAuth.client.userInfo(authRes.accessToken, function (err, user) {
            console.log(err, user);
            if (!err) {
              history.push("/dashboard");
            }
          });
        }
      }
    );
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      history.push("/dashboard");
    }
  }, []);

  return (
    <Fragment>
      <Header forLogin={true} />
      <div className="flex w-3/4 mx-auto px-16 mt-12">
        {/*         
        <div className="flex-1 login-left relative">
          <form>
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-envelope%202.png"
              placeholder="Your Email"
            />
            <div className="mt-7" />
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-lock%201.png"
              placeholder="Password"
              type="password"
            />
            <div className="mt-7" />
            <div className="color-darkblue primary-text-color font-bold text-center">
              Don’t remember your password?
            </div>
            <div className="mt-7" />
            <div className="text-center py-5 rounded-lg text-white border-blue login-btn font-bold">
              Login
            </div>

            <Link
              to="/signup"
              className="text-center primary-text-color font-bold mt-10 hover:none block"
            >
              Don’t have an account ? Register now
            </Link>
          </form>
        </div>
        */}
        <div className="mx-10" />
        <div className="flex-1 relative login-right ml-auto">
          <div onClick={() => loginHandler("google-oauth2")}>
            <div className="w-96 mx-auto">
              <NeumorphBox
                // onChange={}
                icon="http://novo-host.surge.sh/assets/images/brands-and-logotypes%203.png"
                title="Log in with Google"
                extraBoxClasses="py-7 cursor-pointer"
              />
            </div>
          </div>
          <div className="mt-7" />
          {/* <NeumorphBox
            // onChange={}
            icon="http://novo-host.surge.sh/assets/images/logo%203.png"
            title="Log in with Github"
            extraBoxClasses="py-7 cursor-pointer"
          />

          <div className="mt-7" />
          <NeumorphBox
            // onChange={}
            icon="http://novo-host.surge.sh/assets/images/linkedin%203.png"
            title="Log in with Linkedin"
            extraBoxClasses="py-7 cursor-pointer"
          />
          <div className="mt-7" />
          <NeumorphBox
            // onChange={}
            icon="http://novo-host.surge.sh/assets/images/twitter%203.png"
            title="Log in with Twitter"
            extraBoxClasses="py-7 cursor-pointer"
          /> */}
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
