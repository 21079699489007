export const aggregateEquipmentPoints = (allEquips) => {
  const allPoints = Object.values(allEquips);
  allPoints.shift();

  let group = {};

  allEquips.points.forEach((point) => {
    group[point] = [];
    allPoints.forEach((pointItem) => {
      if (point.includes(pointItem.type)) {
        if (pointItem.nodes.length) {
          group[point].push(pointItem);
        }
      }
    });
  });

  const nodes = {};
  const allTimestamps = [];

  Object.keys(group).forEach((key) => {
    nodes[key] = [];
    group[key].forEach((pointItem) => {
      pointItem.nodes.forEach((node) => {
        if (!allTimestamps.includes(node.ts)) {
          allTimestamps.push(node.ts);
        }

        if (node.avg !== null) {
          nodes[key].push({ ts: node.ts, avg: node.avg });
        }
      });
    });
  });

  let aggregated = {};
  Object.keys(nodes).forEach((key) => {
    aggregated[key] = [];
    allTimestamps.forEach((timestamp) => {
      let sum = 0;
      nodes[key].forEach((node) => {
        if (node.ts == timestamp) {
          sum += parseFloat(node.avg);
        }
      });

      aggregated[key].push({
        avg: sum / allTimestamps.length,
        ts: timestamp,
      });
    });
  });

  return aggregated;
};
