import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./components/Login/Login.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import Utilities from "./components/Utilities/Utilities";
import MeterChart from "./components/MeterChart/MeterChart";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Signup from "./components/Signup/Signup.jsx";
import Status from "./components/Status/Status.jsx";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>

          <ProtectedRoute exact path="/dashboard/status/">
            <Status />
          </ProtectedRoute>
          <ProtectedRoute exact path="/dashboard/:meter/">
            <MeterChart />
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/utilities/:utilityMeter">
            <Utilities />
          </ProtectedRoute>

          <ProtectedRoute exact path="/dashboard/:meter/:siteId">
            <MeterChart />
          </ProtectedRoute>

          <ProtectedRoute exact path="/dashboard">
            <Dashboard />
          </ProtectedRoute>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
