import {
  CHANGE_UNIVERSAL_SEARCH_DATA,
  HANDLE_METER_CHANGE,
  HANDLE_METRIC_MULTI_SELECT,
  HANDLE_TIME_CHANGE,
  RESET_SEARCH_STATE,
} from "../actionTypes";

import vav from "../../images/VAV.png";
import ahu from "../../images/AHU.png";
import fcu from "../../images/FCU.png";
import chiller from "../../images/Chiller.png";
import boiler from "../../images/boiler.png";
import kwh from "../../images/kwhMeter.png";
import steam from "../../images/steamMeter.png";
import kwhUtility from "../../images/kwhUtility.png";
import oilUtility from "../../images/oil.png";
import gasUtiliy from "../../images/gas.png";
import propaneUtility from "../../images/propane.png";
import waterUtility from "../../images/water.png";
import sewageUtility from "../../images/sewage.png";
import moment from "moment";

export const allMeterOptions = [
  {
    label: "Kwh Usage",
    value: "kwh",
    description: "Measures the electrity usages in Kwh",
    image: kwh,
    isUtility: false,
    isEquipment: false,
  },
  {
    label: "Steam Usage",
    value: "steam",
    description: "Measures the steam usages in lb/h",
    image: steam,
    isUtility: false,
    isEquipment: false,
  },
  {
    label: "VAV Usages",
    value: "vav",
    isEquipment: true,
    description:
      "Variable air volume terminal unit. VAV systems use a constant air temperature with a variable airflow rate.",
    image: vav,
    isUtility: false,
  },
  {
    label: "AHU Usages",
    value: "ahu",
    isEquipment: true,
    description:
      "Air Handling Unit: An enclosure with a fan that delivers air to space via ductwork and performs one or more of the functions of cleaning, heating, cooling, humidifying, dehumidifying, ventilating, or circulating the air. ",
    image: ahu,
    isUtility: false,
  },
  {
    label: "FCU Usages",
    value: "fcu",
    isEquipment: true,
    description:
      "Fan Coil Unit: A unitary, factory-made enclosure with a fan and coil that recirculates air in a space and performs one or more of the functions of cleaning, heating, cooling, humidifying, dehumidifying, or ventilating the air. Unit heaters are examples of FCUs. ",
    image: fcu,
    isUtility: false,
  },

  {
    label: "Heat Exchange",
    value: "boiler",
    isEquipment: true,
    description: "Equipment to transfer heat between two working fluids.",
    image: boiler,
    isUtility: false,
  },

  {
    label: "Kwh Bills",
    value: "kwh",
    description: "Get Your Electricty bills, Cost vs Consumption",
    isUtility: true,
    description: "View  Kwh Cost and Consumption of entire campus.",
    image: kwhUtility,
  },

  {
    label: "Gas Bills",
    value: "gas",
    description: "Get Your Gas bills, Cost vs Consumption",
    isUtility: true,
    image: gasUtiliy,
  },

  {
    label: "Sewage Bills",
    value: "sewage",
    description: "Get Your Sewage bills, Cost vs Consumption",
    isUtility: true,
    image: sewageUtility,
  },

  {
    label: "Propane Bills",
    value: "propane",
    description: "Get Your Propane bills, Cost vs Consumption",
    isUtility: true,
    image: propaneUtility,
  },

  {
    label: "Water Bills",
    value: "water",
    description: "Get Your Water bills, Cost vs Consumption",
    isUtility: true,
    image: waterUtility,
  },

  {
    label: "Oil Bills",
    value: "oil",
    description: "Get Your Oil bills, Cost vs Consumption",
    isUtility: true,
    image: oilUtility,
  },
];

export const allTimeOptions = [
  // { label: "Today", value: "today", isRange: false, datePickerVal: null },
  {
    label: "Yesterday",
    value: "yesterday",
    isRange: false,
    datePickerVal: "date",
  },
  // { label: "This Week", value: "week", isRange: true, datePickerVal: "week" },
  {
    label: "This Month",
    value: "month",
    isRange: true,
    datePickerVal: "month",
  },
  {
    label: "This Year",
    value: "year",
    isRange: true,
    datePickerVal: "year",
  },
];

const searchState = {
  meter: {
    id: "",
    options: allMeterOptions,
    label: "Select A System",
  },
  site: {
    navName: "All Buildings",
    id: null,
    value: null,
    isAGroup: true,
  },
  time: {
    id: "",
    options: allTimeOptions,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    label: "Today",
    breakdown: "hour",
  },
};

const initialState = {
  universalSearchState: { ...searchState },
  selectedMetrics: [],
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_METER_CHANGE:
      return {
        ...state,
        universalSearchState: {
          ...state.universalSearchState,
          meter: {
            ...state.universalSearchState.meter,
            ...action.payload,
            id: action.payload ? action.payload.value : null,
          },
        },
      };
      break;

    case HANDLE_TIME_CHANGE:
      return {
        ...state,
        universalSearchState: {
          ...state.universalSearchState,
          time: {
            ...state.universalSearchState.time,
            ...action.payload,
          },
        },
      };
      break;

    case HANDLE_METRIC_MULTI_SELECT:
      if (action.payload == "reset") {
        return {
          ...state,
          selectedMetrics: [],
        };
      }

      let isIndex;
      const alreadyPresent = state.selectedMetrics.filter((metric, idx) => {
        if (metric.value == action.payload.value) {
          isIndex = idx;
          return metric;
        }
      });

      if (alreadyPresent.length) {
        return {
          ...state,
          selectedMetrics: state.selectedMetrics.filter(
            (meter, idx) => idx !== isIndex
          ),
        };
      } else {
        return {
          ...state,
          selectedMetrics: [...state.selectedMetrics, action.payload],
        };
      }
      break;

    case RESET_SEARCH_STATE:
      return {
        ...state,
        universalSearchState: {
          ...searchState,
          site: {
            ...searchState.site,
          },
        },
      };

    default:
      return state;
  }
}
