import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

function DatePickerRender({ picker, onChange, startDate }) {
  //   const [startDate, setStartDate] = useState(new Date());

  if (picker == "date") {
    return (
      <DatePicker
        selected={startDate}
        // onChange={(date) => setStartDate(date)}
        onChange={onChange}
        inline
      />
    );
  }

  if (picker == "month") {
    return (
      <DatePicker
        selected={startDate}
        // onChange={(date) => setStartDate(date)}
        onChange={onChange}
        inline
        showMonthYearPicker
      />
    );
  }

  if (picker == "year") {
    return (
      <DatePicker
        selected={startDate}
        // onChange={(date) => setStartDate(date)}
        onChange={onChange}
        inline
        showYearPicker
      />
    );
  }
}

export default DatePickerRender;
