import { Menu, Transition } from "@headlessui/react";
import { FaRegBell } from "react-icons/fa";
import React, { Fragment, useEffect, useState } from "react";
import webAuth from "../../components/Login/auth0congif";
import { useHistory } from "react-router-dom";
import "./Header.css";
import { Modal } from "antd";
import UserProfileModal from "./UserProfileModal";

const Header = React.memo(({ forLogin }) => {
  const [user, setUser] = useState(null);
  const [userDropdown, setUserDropDown] = useState(false);

  let [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      webAuth.client.userInfo(
        localStorage.getItem("accessToken"),
        (err, user) => {
          if (!err) {
            setUser(user);
          }
          if (err) {
            console.log(err);
            if (err.description !== "Too Many Requests") {
              return handleLogout();
            }
          }
        }
      );
    }
  }, [localStorage.getItem("accessToken")]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    history.push("/");
    window.location.reload();
  };

  return (
    <div className={"wrapper header z-50  pt-10"}>
      <UserProfileModal user={user} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={" mx-auto flex items-center justify-between px-10"}>
        <div className="">
          <img
            className="novo-logo-img"
            src="https://richmond.novo.cloud/images/novo%20logo.svg"
          />
        </div>

        <div className="">
          <img
            className="univ-logo-img"
            src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d0/UR_Shield.svg/1200px-UR_Shield.svg.png"
          />
        </div>

        {!forLogin && user && Object.keys(user).length && (
          <div className="flex items-center">
            <button className="">
              <span className="sr-only">View notifications</span>
              <FaRegBell className="h-6 w-6 secondary-text-color" />
            </button>

            <div className="ml-3">
              <p className="m-0 p-0 secondary-text-color font-sm font-light">
                Hey Morning
              </p>
              <h3 className="m-0 p-0  primary-text-color text-base font-bold">
                {user.name}
              </h3>
            </div>

            <Menu as="div" className="relative flex-shrink-0 ml-4">
              <>
                <div>
                  <Menu.Button className="rounded-full flex text-sm text-white focus:outline-none focus:bg-sky-900 focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-900 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    <img
                      onClick={() => setUserDropDown((x) => !x)}
                      className="rounded-full avatar"
                      src={user.picture}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={userDropdown}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  >
                    <Menu.Item>
                      <>
                        <div
                          onClick={() => setIsOpen(true)}
                          className={
                            // "bg-gray-100 my-5"
                            "block  py-2 px-4 text-sm text-gray-700 font-bold"
                          }
                        >
                          {user.name}
                        </div>

                        <div
                          onClick={handleLogout}
                          className={
                            // "bg-gray-100"
                            "block py-2 px-4 text-sm text-gray-700 cursor-pointer"
                          }
                        >
                          Logout
                        </div>
                      </>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            </Menu>
          </div>
        )}
      </div>

      <div
        className=" mt-5 mx-auto"
        style={{ border: "1px solid #EFEFEF", width: "95%" }}
      ></div>
    </div>
  );
});

export default Header;
