import React, { useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./MapBox.css";
import { FaMapMarkerAlt, FaRegBuilding } from "react-icons/fa";
import { connect } from "react-redux";
import { handleSiteSelected } from "../../../redux/actions";
import { GiWindow } from "react-icons/gi";
import { FiMap } from "react-icons/fi";
import { BiMap } from "react-icons/bi";
import moment from "moment";
import { getWeatherForADay, read } from "../../API/API";
import { onlyHourPoints } from "../../../helpers/onlyHourPoints";
import useSelection from "antd/lib/table/hooks/useSelection";
import { initialChartOptions } from "../../MetersCard/MetersCard";
import { Chart, registerables } from "chart.js";
import { resParse } from "../../../helpers/parser";
import cloudIcon from "../../../images/clouds.png";
Chart.register(...registerables);

function MapBox({
  zoom,
  setZoom,
  selectedSite,
  allSites,
  setSelectedSite,
  buildings,
  weatherObj,
}) {
  let selectedGroup = [];
  let selectedGroupArea = 0;

  selectedGroup =
    buildings[selectedSite.value ? selectedSite.value : "allSites"];

  selectedGroup.forEach((site) => {
    selectedGroupArea += parseInt(site.area.replace(/\D/g, ""));
  });

  const [lat, setLat] = useState(37.577192);
  const [lon, setLon] = useState(-77.538557);

  const [weatherData, setWeatherData] = useState({});

  const [chartInstance, setChartInstance] = useState({});

  const handleMapMarkerClick = (site) => {
    if (Object.keys(site).length && site.id !== null) {
      setZoom(19);

      setSelectedSite(site);

      setLat(site.geoLat);
      setLon(site.geoLon);
    }
    if (site.isAGroup) {
      setZoom(15);
    }
  };

  useEffect(() => {
    (async () => {
      const weatherRes = await read(
        `dash_gql_returnWeatherData(${moment().format(
          "YYYY-MM-DD"
        )}, ${moment().format("YYYY-MM-DD")}, "month")`
      );

      const weather = resParse(weatherRes.data.val);

      console.log(weather);

      if (weather.data.weather[0].nodes.length) {
        setWeatherData({
          ...weather.data.weather[0].nodes[0],
          unit: weather.data.weather[0].unit,
        });
      } else {
        setWeatherData({});
      }
    })();
  }, []);

  useEffect(() => {
    handleMapMarkerClick(selectedSite);
  }, [selectedSite]);

  useEffect(() => {
    if (weatherData.length) {
      const color = "#0da4e7";
      const chartBgColor = "rgba(13, 164, 231, 0.1)";
      const ctx = document.getElementById("weatherChart").getContext("2d");

      if (Object.keys(chartInstance).length) {
        try {
          chartInstance.destroy();
        } catch (e) {
          console.log("Cannot clear Instance");
        }
      }

      const gradient = ctx.createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, chartBgColor);
      gradient.addColorStop(0.5, "rgba(255, 255, 255, 0.2)");
      gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

      let dataForChart = {
        labels: weatherData.map((x) => x.ts),
        datasets: [
          {
            data: weatherData.map((x) => x.val),
            fillColor: chartBgColor,
            fill: true,
            lineTension: 0.2,
            backgroundColor: gradient,
            borderColor: color,
            // pointBackgroundColor: color,
            // pointBorderColor: "#fff",
            // pointBorderWidth: 5,
            pointRadius: 0,
            pointHoverRadius: 5,
            borderWidth: 3,
            lineTension: 0.5,
            fill: true,
          },
        ],
      };

      console.log(dataForChart);

      const chart = new Chart(ctx, {
        type: "line",
        options: {
          ...initialChartOptions,
          y: {
            ticks: {
              stepSize: 0.1,
            },
          },
        },
        data: dataForChart,
      });

      setChartInstance(chart);
    }
  }, [weatherData]);

  console.log();

  const mapInfos = [
    {
      icon: <FaRegBuilding className="primary-text-color" size="20px" />,
      label: "Name",
      value: selectedSite.navName,
    },
    {
      icon: <GiWindow className="primary-text-color" size="20px" />,
      label: selectedSite.isAGroup ? "No of Sites" : "Floors",
      value:
        !selectedSite.id && !selectedSite.value
          ? allSites.length
          : selectedSite.value
          ? buildings[selectedSite.value].length
          : selectedSite.nodes.length,
    },
    {
      icon: <FiMap className="primary-text-color" size="20px" />,
      label: "Sqft",
      value: selectedSite.isAGroup ? (
        <>
          {selectedGroupArea.toLocaleString()} ft<sup>2</sup>
        </>
      ) : (
        selectedSite.area
      ),
    },
    {
      icon: <BiMap className="primary-text-color" size="20px" />,
      label: "Location",
      value: "Richmond, VA",
    },
  ];

  return (
    <div className="w-full">
      <div className=" map-wrapper h-96 border border-1  overflow-hidden">
        <div className="map  overflow-hidden">
          <div className="relative h-96">
            <>
              <div
                style={{ zIndex: "9" }}
                className=" absolute maps-card info-card  h-full"
              >
                <div className="h-full rounded rounded-xl py-6 px-6 flex flex-col justify-between py-10">
                  {mapInfos.map((info) => (
                    <div className="info flex items-center">
                      <div className="icon">{info.icon}</div>

                      <div>
                        <p className="m-0 text-xs secondary-text-color p-0  label mb-1 uppercase">
                          {info.label}
                        </p>
                        <h2 className="m-0 text-sm primary-text-color font-bold tracking-wide">
                          {info.value}
                        </h2>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{ zIndex: "9" }}
                className=" absolute maps-card weather-card  h-full overflow-hidden"
              >
                <div className="h-full px-6 flex flex-col justify-between py-7 text-center flex flex-col justify-center items-center relative">
                  <h1 className="primary-text-color font-bold">
                    Weather from Richmond
                  </h1>
                  <div className="flex  items-center  justify-center">
                    <img
                      src={cloudIcon}
                      alt="clouds-img"
                      width="100px"
                      className="mx-auto"
                    />
                    <h1 className="primary-text-color font-bold weather-degree">
                      {Object.keys(weatherData).length
                        ? `${weatherData.avg} °F`
                        : null}
                    </h1>
                  </div>
                  <div className="flex justify-between w-full items-center">
                    <p className="primary-text-color m-0  text-xs">
                      {moment().format("dddd, hh:mm a")}
                    </p>
                    {/* <p className="secondary-text-color m-0">Mostly cloudy</p> */}
                  </div>
                  <div
                    className="chart absolute -bottom-12 -left-1 weatherchart"
                    style={{ zIndex: -10 }}
                  >
                    <canvas id="weatherChart"></canvas>
                  </div>
                </div>
              </div>
            </>

            <div className="h-full">
              <GoogleMapReact
                onZoomAnimationEnd={(e) => {
                  setZoom(e);
                }}
                bootstrapURLKeys={{
                  key: "AIzaSyBYSAlO-VejkTOjhXFrN6H11Crbo_-KWFY",
                }}
                center={{
                  lat: lat,
                  lng: lon,
                }}
                zoom={zoom}
                options={{
                  disableDefaultUI: true,
                }}
                opacity={0.1}
              >
                {allSites.map((site) =>
                  site.geoLat && site.geoLon ? (
                    <div
                      lat={site.geoLat}
                      lng={site.geoLon}
                      onClick={() => handleMapMarkerClick(site)}
                      className="marker-container cursor-pointer"
                    >
                      {selectedSite.id == site.id && zoom == 19 ? (
                        <div className="map-marker  p-4 rounded rounded-xl">
                          <div>
                            <div className="neu-box flex items-center z-10 rounded rounded-xl">
                              <div className="neu-icon p-2">
                                <img src="http://novo-host.surge.sh/assets/images/Group%20137.png" />
                              </div>
                              <div className="ml-4">
                                <h1 className="color-darkblue font-bold text-lg">
                                  {selectedSite.navName}
                                </h1>
                                <p>{selectedSite.area}</p>
                              </div>
                            </div>
                          </div>
                          <div className="absolute z-0 loc-icon">
                            <img
                              src="http://novo-host.surge.sh/assets/images/Ellipse%2056.png"
                              alt="loc-icon"
                            />
                          </div>
                        </div>
                      ) : (
                        <FaMapMarkerAlt color="#0da4e7" size="32px" />
                      )}
                    </div>
                  ) : null
                )}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedSite: state.buildings.selectedSite,
    allSites: state.buildings.allSites,
    buildings: state.buildings,
  };
};

const mapDispatchToProps = {
  setSelectedSite: handleSiteSelected,
};
export default connect(mapStateToProps, mapDispatchToProps)(MapBox);
