import React, { useEffect, useState } from "react";
import { ResponsiveAreaBump, AreaBump } from "@nivo/bump";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import tConvert from "../../helpers/tConvert";
import Loader from "../../Resueables/Loader/Loader";
import { setErrorModal } from "../../redux/actions";
import Modal from "../../Resueables/Modals/Modal";
import { BiErrorAlt } from "react-icons/bi";
import moment from "moment";

function MultipleSitesChart({
  loadingSomething,
  selectedSite,
  meterData,
  isEquipment,
  setErrorModal,
  errorModal,
}) {
  const [bumpChartData, setBumpChartData] = useState([]);

  const transformDataForAreaBump = (allSitesData) => {
    try {
      const bumpData = [];
      let data = [];

      for (let i in allSitesData) {
        data = [];
        if (allSitesData[i].nodes.length) {
          for (let j in allSitesData[i].nodes) {
            const time = allSitesData[i].nodes[j].ts;
            data.push({
              x: moment(time.substring(0, time.lastIndexOf("-"))).format(
                "hh:mm a"
              ),
              y: parseFloat(allSitesData[i].nodes[j].avg),
            });
          }
        } else {
          continue;
        }
        bumpData.push({
          id: allSitesData[i].site_name,
          data: data,
        });
      }
      console.log(bumpData);

      return bumpData;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!meterData) return;

    if (!isEquipment) {
      setBumpChartData(transformDataForAreaBump(Object.values(meterData)));
    }
  }, [meterData]);

  if (isEquipment) {
    return <></>;
  }

  return (
    <div className="">
      <Modal
        open={errorModal}
        setOpen={setErrorModal}
        modalHeading="No data available"
        modalText="We are having trouble, fetching data at this time stamp"
        modalType="error"
        modalIcon={<BiErrorAlt className="" />}
      />
      {!isEquipment && bumpChartData && bumpChartData.length ? (
        <div className="w-full" style={{ height: "850px" }}>
          <ResponsiveAreaBump
            data={bumpChartData}
            margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
            spacing={20}
            colors={{ scheme: "nivo" }}
            blendMode="multiply"
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "CoffeeScript",
                },
                id: "dots",
              },
              {
                match: {
                  id: "TypeScript",
                },
                id: "lines",
              },
            ]}
            startLabel="id"
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -36,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            align="middle"
          />
        </div>
      ) : loadingSomething ? (
        <>
          <Loader center size="100px" />
        </>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedSite: state.buildings.selectedSite,
    errorModal: state.buildings.errorModal,
  };
};

const mapDispatchToProps = {
  setErrorModal: setErrorModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(MultipleSitesChart);
