import React, { Fragment, useEffect, useState } from "react";
import Header from "../../Resueables/Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import "./Utilities.css";
import UniversalSearch from "../../Resueables/UniversalSearch";
import { useParams } from "react-router-dom";
import KwhUtility from "./AllUtilitues/KwhUtility";
import { BsFillLightningFill } from "react-icons/bs";
import { IoIosCalculator, IoMdWallet } from "react-icons/io";
import { FaCube } from "react-icons/fa";
import Toggle from "../../Resueables/UniversalSearch/Toggle/Toggle";
import { Chart } from "chart.js";
import { setAllUtilityMeters, setCostActive } from "../../redux/actions";
import { connect, useStore } from "react-redux";
import { classNames } from "../../helpers/classNames";
import AddData from "./AddData";
import { getBills, getBillsByMeterNumber, getMetersByTags } from "../API/API";
import moment from "moment";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import { Transition } from "@headlessui/react";
import { allMeterOptions } from "../../redux/reducers/search.reducers";
import dayjs from "dayjs";

function Utilities({
  isCostActive,
  setCostActive,
  universalSearchState,
  utilitiyMeters,
  setAllUtilityMeters,
}) {
  const params = useParams();
  const { utilityMeter } = params;

  const [consChart, setConsChart] = useState(null);
  const [costChart, setCostChart] = useState(null);
  const [addData, setAddData] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState({
    allMeter: true,
    meter_number: null,
  });

  const [allBills, setAllBills] = useState([]);
  const [currentBill, setCurrentBill] = useState({});

  const [costCalanderYear, setCostCalanderYear] = useState(true);
  const [consCalanderYear, setConsCalanderYear] = useState(true);

  const [currentlyShowingBills, setCurrentlyShowingBills] = useState([]);

  const [showInfo, setShowInfo] = useState(false);

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      chartAreaBorder: {
        borderWidth: 0,
      },
    },

    layout: {
      padding: {
        top: 10,
      },
    },

    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: true,
          font: {
            size: 15,
            weight: "bolder",
            color: "#383874",
          },
        },
        beginAtZero: true,

        grid: {
          display: false,
          drawBorder: false,
        },
      },

      y: {
        ticks: {
          display: true,
        },

        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },

    layout: {
      padding: 0,
    },
  };

  useEffect(() => {
    (async () => {
      // console.log("SM, UL", selectedMeter, utilitiyMeters.length);
      // if (selectedMeter && utilitiyMeters.length) {

      const bills = await getBills(
        selectedMeter
          ? selectedMeter.meter_number && !selectedMeter.allMeter
            ? selectedMeter.meter_number
            : null
          : utilitiyMeters[0].meter_number,
        null,
        params.utilityMeter,
        dayjs(universalSearchState.time.endData).year().toString()
      );

      console.log("BILLS", bills);

      if (bills.data.data) {
        const allBillsLocal = bills.data.data.utility_meters_data;
        setAllBills(bills.data.data.utility_meters_data);

        if (selectedMeter.meter_number == null && selectedMeter.allMeter) {
          const curBills = allBillsLocal.filter((x) =>
            universalSearchState.time.range == "year"
              ? x.year == universalSearchState.time.label
              : universalSearchState.time.range == "month"
              ? x.month == universalSearchState.time.label
              : null
          );

          setCurrentBill({
            cost: curBills.reduce(
              (n, { cost }) => Math.round(parseFloat(n) + parseFloat(cost)),
              0
            ),
            consumption: curBills.reduce(
              (n, { consumption }) =>
                Math.round(parseFloat(n) + parseFloat(consumption)),
              0
            ),
          });
        } else {
          const curBill = allBillsLocal.filter((x) =>
            universalSearchState.time.range == "year"
              ? x.year == universalSearchState.time.label
              : universalSearchState.time.range == "month"
              ? x.month == universalSearchState.time.label
              : null && x.meter_number == selectedMeter.meter_number
          );

          setCurrentBill({
            cost: curBill.reduce(
              (n, { cost }) => Math.round(parseFloat(n) + parseFloat(cost)),
              0
            ),
            consumption: curBill.reduce(
              (n, { consumption }) =>
                Math.round(parseFloat(n) + parseFloat(consumption)),
              0
            ),
          });
        }
      }
      // }
    })();
  }, [utilitiyMeters, selectedMeter, universalSearchState.time, addData]);

  useEffect(() => {
    const sortedBills = allBills.sort((a, b) =>
      a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
    );

    plotCostChart(sortedBills);
    plotConsChart(sortedBills);
  }, [allBills, selectedMeter, addData]);

  useEffect(() => {
    const allBillsCopy = JSON.parse(JSON.stringify(allBills));

    const indexOfJuly = allBillsCopy.findIndex(
      (x) => x.month.toLowerCase() == "july"
    );

    const restOfBills = allBillsCopy.splice(indexOfJuly);

    const fiscalYearBills = [...restOfBills, ...allBillsCopy];

    console.log(fiscalYearBills);

    if (costCalanderYear == false) {
      plotCostChart(fiscalYearBills);
    } else {
      plotCostChart(allBills);
    }

    if (consCalanderYear == false) {
      plotConsChart(fiscalYearBills);
    } else {
      plotConsChart(allBills);
    }
  }, [costCalanderYear, consCalanderYear, addData]);

  const plotCostChart = (bills) => {
    try {
      if (Chart.getChart("gas-cost-chart") != undefined) {
        Chart.getChart("gas-cost-chart").destroy();
      }
      const allMetersSelected =
        selectedMeter.meter_number == null && selectedMeter.allMeter == true;

      let sets = [];
      if (allMetersSelected) {
        moment.months().forEach((month) => {
          let monthGroup = bills.filter((x) => x.month == month);
          sets.push({
            label: month,
            cost: monthGroup.reduce(
              (n, { cost }) => parseInt(n) + parseInt(cost),
              0
            ),
            consumption: monthGroup.reduce(
              (n, { consumption }) => parseInt(n) + parseInt(consumption),
              0
            ),
          });
        });

        const setsCopy = JSON.parse(JSON.stringify(sets));

        const indexOfJuly = setsCopy.findIndex(
          (x) => x.label.toLowerCase() == "july"
        );

        const restOfBills = setsCopy.splice(indexOfJuly);

        const fiscalYearBills = [...restOfBills, ...setsCopy];

        if (!costCalanderYear) {
          sets = fiscalYearBills;
        }
      }

      const ctxDom2 = document.getElementById("gas-cost-chart");
      if (!ctxDom2) return;
      const ctx2 = ctxDom2.getContext("2d");

      const chartConfigs = {
        fillColor: "#64E480",
        fill: true,
        backgroundColor: "rgba(100, 228, 128, 0.05)",
        borderColor: "#64E480",
        pointRadius: 2,
        pointHoverRadius: 5,
        borderWidth: 3,
        lineTension: 0.5,
      };

      const chartDataLocal = {
        labels: allMetersSelected
          ? sets.map((x) => x.label)
          : bills.map((x) => x.month),
        datasets: allMetersSelected
          ? [
              {
                data: sets.map((x) => x.cost),
                ...chartConfigs,
              },
            ]
          : [
              {
                data: bills.map((x) => x.cost),
                ...chartConfigs,
              },
            ],
      };

      // const chartDataLocal2 = {
      //   labels: allMetersSelected
      //     ? datasets_for_all_meters[0].label
      //     : bills.map((x) => x.month),
      //   datasets: allMetersSelected
      //     ? datasets_for_all_meters
      //     : [
      //         {
      //           data: bills.map((x) => x.cost),
      //           label: "Label",
      //         },
      //         {
      //           label: "legend1",
      //           data: [40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40],
      //           borderDash: [10, 5],
      //         },
      //       ],
      // };

      var costChart = new Chart(ctx2, {
        type: "line",
        data: chartDataLocal,
        options: {
          ...chartOptions,
          scales: {
            ...chartOptions.scales,
            y: {
              ...chartOptions.scales.y,
              ticks: {
                ...chartOptions.scales.y.ticks,
                callback: (label) => {
                  console.log(label);
                  return label + " $";
                },
              },
            },
          },
        },
      });

      setCostChart(costChart);
    } catch (e) {
      console.log(e);
    }
  };

  const plotConsChart = (bills) => {
    try {
      setCurrentlyShowingBills(bills);
      if (Chart.getChart("gas-cons-chart") != undefined) {
        Chart.getChart("gas-cons-chart").destroy();
      }

      const ctxDom = document.getElementById("gas-cons-chart");
      const allMetersSelected =
        selectedMeter.meter_number == null && selectedMeter.allMeter == true;

      let sets = [];
      if (allMetersSelected) {
        moment.months().forEach((month) => {
          let monthGroup = bills.filter((x) => x.month == month);
          sets.push({
            label: month,
            cost: monthGroup.reduce(
              (n, { cost }) => parseInt(n) + parseInt(cost),
              0
            ),
            consumption: monthGroup.reduce(
              (n, { consumption }) => parseInt(n) + parseInt(consumption),
              0
            ),
          });
        });

        const setsCopy = JSON.parse(JSON.stringify(sets));

        const indexOfJuly = setsCopy.findIndex(
          (x) => x.label.toLowerCase() == "july"
        );

        const restOfBills = setsCopy.splice(indexOfJuly);

        const fiscalYearBills = [...restOfBills, ...setsCopy];

        if (!consCalanderYear) {
          sets = fiscalYearBills;
        }
      }

      if (!ctxDom) return;

      const ctx = ctxDom.getContext("2d");

      const chartConfigs = {
        fillColor: "#0EA4E7",
        fill: true,
        backgroundColor: "rgba(15,164,231, 0.05)",
        borderColor: "#0EA4E7",
        pointRadius: 2,
        pointHoverRadius: 5,
        borderWidth: 3,
        lineTension: 0.5,
      };

      const chartDataLocal = {
        labels: sets.map((x) => x.label),
        datasets: allMetersSelected
          ? [
              {
                data: sets.map((x) => x.consumption),
                ...chartConfigs,
              },
            ]
          : [
              {
                data: bills.map((x) => x.consumption),
                ...chartConfigs,
              },
            ],
      };

      var consChart = new Chart(ctx, {
        type: "line",
        data: chartDataLocal,
        options: {
          ...chartOptions,
          scales: {
            ...chartOptions.scales,
            y: {
              ...chartOptions.scales.y,
              ticks: {
                ...chartOptions.scales.y.ticks,
                callback: (label) => {
                  return `${label} ${
                    utilityMeter == "propane" || utilityMeter == "oil"
                      ? " Gal"
                      : " CCF"
                  }`;
                },
              },
            },
          },
        },
      });

      console.log("CONS CHART", consChart);

      setConsChart(consChart);
    } catch (e) {
      console.log(e);
    }
  };

  const currentMetric = allMeterOptions.filter(
    (x) => x.value == utilityMeter
  )[0];

  return (
    <div className="flex">
      <div className="container mx-auto w-10/12">
        <Header forLogin={false} />
        <div className="spacer-top"></div>

        <UniversalSearch
          setSelectedUtilityMeter={setSelectedMeter}
          selectedUtilityMeter={selectedMeter}
          auotSwitch={false}
          setZoom={() => console.log("ZOom SET")}
          showTimePanel={false}
          setShowTimePanel={() => console.log("SET TIME PAMEL")}
          isUtilitySearchBar={true}
          addDataBtn={true}
          setAddData={setAddData}
        />
        <div className="spacer-top"></div>
        <h1 className="metric-headline text-4xl  font-bold text-gray-500">
          {" "}
          {currentMetric.label}
        </h1>
        <div className="spacer-top"></div>

        {addData ? (
          <>
            <AddData
              selectedMeter={selectedMeter}
              allBills={allBills}
              currentBill={currentBill}
            />
          </>
        ) : null}

        {!addData ? (
          utilityMeter == "kwh" ? (
            <KwhUtility />
          ) : (
            <>
              <div className="utility-cards w-full flex items-center justify-between">
                {utilityMeter == "gas" ? (
                  <div
                    className={classNames(
                      "utility-card v2 overflow-hidden flex-1 relative  flex items-center"
                    )}
                  >
                    <div
                      className={classNames(
                        "animating-layover absolute w-full h-full primary-bg-color",
                        showInfo ? "animate" : ""
                      )}
                    ></div>

                    {showInfo && (
                      <div className="info visibility-none w-full text-center h-full flex items-center justify-center absolute w-full top-0 left-0">
                        <div className="px-5">
                          <h1
                            className="text-xs text-white"
                            style={{ fontSize: "10px", color: "#fff" }}
                          >
                            The British thermal unit is a unit of heat; it is
                            defined as the amount of heat required to raise the
                            temperature of one pound of water by one degree
                            Fahrenheit
                          </h1>
                        </div>
                      </div>
                    )}
                    {showInfo && (
                      <div className="close-btn absolute right-0 top-1 w-full text-right">
                        <AiOutlineClose
                          onClick={() => setShowInfo(false)}
                          className="text-white ml-auto mr-2 mt-2 cursor-pointer font-bold"
                        ></AiOutlineClose>
                      </div>
                    )}

                    <div className="icon flex justify-center w-1/4">
                      <div className="icon-wrapper flex items-center justify-center">
                        <IoIosCalculator color="#0EA4E7" size="28px" />
                      </div>
                    </div>

                    <div className="w-4/5">
                      <p className="font-normal m-0 flex  items-center">
                        <span>BTU's</span>
                        <AiOutlineInfoCircle
                          onClick={() => setShowInfo(true)}
                          className="primary-text-color ml-2"
                        ></AiOutlineInfoCircle>
                      </p>
                      <h1 className="font-normal m-0">
                        {currentBill ? (
                          <h1 className="font-normal m-0">
                            {currentBill.consumption?.toLocaleString()}
                          </h1>
                        ) : (
                          <p className="font-bold m-0">Unavailable</p>
                        )}
                      </h1>
                    </div>
                  </div>
                ) : null}

                <div className={"utility-card v2 flex-1  flex items-center"}>
                  <div className="icon flex justify-center w-1/4">
                    <div className="icon-wrapper flex items-center justify-center">
                      <FaCube color="#023AFF" size="28px" />
                    </div>
                  </div>

                  <div className="w-4/5">
                    <p className="font-normal m-0">Total CCF</p>
                    {currentBill ? (
                      <h1 className="font-normal m-0">
                        {currentBill.consumption?.toLocaleString()}
                      </h1>
                    ) : (
                      <p className="font-bold m-0">Unavailable</p>
                    )}
                  </div>
                </div>

                <div className={"utility-card v2 flex-1  flex items-center"}>
                  <div className="icon flex justify-center w-1/4">
                    <div className="icon-wrapper flex items-center justify-center">
                      <IoMdWallet color="#64E480" size="28px"></IoMdWallet>
                    </div>
                  </div>

                  <div className="w-4/5">
                    <p className="font-normal m-0">Total Cost</p>
                    {currentBill ? (
                      <h1 className="font-normal m-0">
                        ${currentBill.cost?.toLocaleString()}
                      </h1>
                    ) : (
                      <p className="font-bold m-0">Unavailable</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="spacer-top"></div>
              <div className="utility-chart-wrapper bg-white">
                <div className="flex items-center mb-8">
                  <h1 className="primary-text-color text-2xl font-bold ">
                    {currentMetric?.label.split(" ")[0]} Consumption
                  </h1>

                  <div className="legend  mx-auto">
                    <span
                      className="w-2 h-2 inline-block  mr-2 rounded rounded-full"
                      style={{ backgroundColor: "#0EA4E7" }}
                    ></span>
                    {/* <span className="font-bold">Consumption</span> */}
                  </div>
                  <div
                    style={{ zIndex: "2" }}
                    className="ml-auto"
                    onClick={() => setConsCalanderYear((prev) => !prev)}
                  >
                    <Toggle
                      totalWidth="250px"
                      leftSideWidth="50%"
                      rightSideWidth="50%"
                      toggleBetween={["Calander Year", "Fiscal Year"]}
                      isOnLeft={consCalanderYear}
                    />
                  </div>
                </div>

                <div style={{ height: "383.42px", width: "100%" }}>
                  <canvas id="gas-cons-chart"></canvas>
                </div>
              </div>

              <div className="spacer-top"></div>
              <div className="utility-chart-wrapper bg-white">
                <div className="flex items-center mb-8">
                  <h1 className=" primary-text-color text-2xl font-bold">
                    {currentMetric?.label.split(" ")[0]} Cost / $
                  </h1>
                  ,
                  <div className="legend  mx-auto">
                    <span
                      className="w-2 h-2 inline-block  mr-2 rounded rounded-full"
                      style={{ backgroundColor: "#64E480" }}
                    ></span>
                    {/* <span className="font-bold">Cost</span> */}
                  </div>
                  <div style={{ zIndex: "2" }} className="ml-auto">
                    <div
                      style={{ zIndex: "2" }}
                      onClick={() => setCostCalanderYear((prev) => !prev)}
                    >
                      <Toggle
                        totalWidth="250px"
                        leftSideWidth="50%"
                        rightSideWidth="50%"
                        isOnLeft={costCalanderYear}
                        toggleBetween={["Calander Year", "Fiscal Year"]}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ height: "383.42px", width: "100%" }}>
                  <canvas id="gas-cost-chart"></canvas>
                </div>
              </div>

              <div className="spacer-top"></div>
            </>
          )
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isCostActive: state.buildings.isCostActive,
    universalSearchState: state.search.universalSearchState,
    utilitiyMeters: state.utilities.utilitiyMeters,
  };
};

const mapDispatchToProps = {
  setCostActive: setCostActive,
  setAllUtilityMeters: setAllUtilityMeters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Utilities);
